.userroom{

  font-size: 17px;
  h1{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fio{
    display: flex;
    flex-direction: row;
    .avatar{
      img{
        height: 100px;
        width: 100px;
        margin-right: 10px;
      }
    }
    span{
      margin-left: 10px;
    }
  }

  .address{
    margin-top: 10px;
  }

  .date{
    margin-top: 10px;
  }

  .btnChangeProfile{
    display: flex;
    flex-direction: row;
    a{
      height: 50px;
      width: auto;
      text-decoration: none;
      &:hover{
        text-decoration: none;
        color: white;
      }
    }
  }
  
.change-avatar{
  display: flex;
  flex-flow: row;
  .change-avatar-items{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ant-avatar{
  margin: 0 !important;
}

.btn-link{
  margin-top: 10px;
  margin-right: 20px;
  padding: 4px 15px;
  height: min-content !important;
  font-size: 14px;
  border-radius: 2px;
}
.ant-card-body{
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;
  &a{
    height: 20%;
  }
}
  .myCourses{
    ul{  
      li{
        display: flex;
        i{
          margin-right: 20px;
        }
        a{
          width:400px;
        }
        p{
          width: 500px;
        }
        &*{
          align-self: center;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 800px){
  .person-course-row{
    display: inline-block;
    width: 100%;
  }
  .ant-card-body{
    p{
      text-align: center;
      word-wrap: break-word;
      margin-bottom: 10px;
    }
    &a{
      height: 40%;
    }
  }
  .ant-col-8{
    max-width: 100%;
    .btn-link{
      margin-right: 0 !important;
      margin-top: 5px;
    }
  }

  .change-avatar{
    flex-direction: column !important;
  }

  .change-avatar-items{
    margin-top: 15px;
  }

  .userroom{
    .fio{
      span{
        margin-left: 0;
      }
    }
  }
}
