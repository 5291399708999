.info{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

 &-title{
   font-size: 50px;
   font-weight: 600;
   color: $blue-color;
 }

  .bolt-text{
    font-size: 15px;
    font-weight: 600;
  }


  ul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px 0;

    li{
      padding: 10px 0;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      i{
        color: $orange-color;
        font-size: 60px;
        margin-bottom: 10px;

      }
      h2{
        margin-bottom: 10px;
      }

    }

  }


  .accordion{

    &-card{

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .card{
        font-size: 15px;
        width: 18%;
        border-radius: 10px;

        transition: box-shadow 0.5s;

        &:hover{
          cursor: pointer;
          box-shadow: 0 0 10px $blue-color;
        }

        .title{
          font-size: 17px;
          font-weight: 600;
          color: white;
          text-align: center;
          height: 25%;
          overflow: hidden;
          padding: 5px;
          background-color: $blue-color;
        }

        .text{
          padding: 5px;
          text-align: center;

        }
      }
    }
  }
}

.preloader{
  position: absolute;
  left: 50%;
  top: 20%;
}

.ant-spin-container{
  h5{
    margin-bottom: 0;
    font-size: 19px;
    font-weight: bold;
  }
}
.ant-back-top{
  svg{
    font-size: 29px;
  }
}