.info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .name{
    font-size: 30px;
    font-weight: 600;
  }
  .duration{
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn-duration{
    width: 400px;
    margin: 10px 0;

  }
}
.about{
  .item{
    margin-bottom: 20px;

    .title{
      font-size: 25px;
      font-weight: 600;
    }

    .text-arr{
      .course-part{
        margin-left: 10px;
        i{
          margin-right: 10px;
          color: #A64E00;
        }
      }
      span{
        padding: 5px 10px;
        border-radius: 20px;
        background-color: $orange-color;
        margin-right: 10px;
        border: 1px solid $orange-color;
        color: white;

        i{
        margin-right: 10px;
        color: white;
      }
      }
    }
  }
}

.ant-tree-list-holder-inner{
  background-color: rgb(240 242 245);
  div{
    width: 100%;
  }
  .test-progress{
    display: flex;
    div.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-default{
      display: flex !important;
      .ant-progress-text{
        display: block !important;
        align-self:center;
      }
    }
    span{
      width: 105px !important;
    }
    .anticon.anticon-check-circle{
      width: 10px !important;
    }
    .ant-progress-outer{
      width: 150px;
    }
    div.ant-progress.ant-progress-line.ant-progress-status-success.ant-progress-show-info.ant-progress-default{
      display: flex !important;
      .ant-progress-text{
        display: block !important;
        align-self:center;
      }
    }
  }
}

.test-data-view{
  display: flex;
  .text-question{
    width: 60%;
    font-size: 16px;
    margin-right: 15px;
  }
  & .ant-image-img{
    object-fit: cover !important;
    width: 300px !important;
  }
}