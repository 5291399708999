.upload-pdf-file{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.ant-form-item-control-input-content{
  flex-direction: column;
  span{
    word-wrap: break-word;
  }
}

.PDF-upload-course-files{
  max-width: 350px !important;
  justify-content: space-between;
}