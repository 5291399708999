.slider{
  padding-top: 10px;
  padding-bottom: 10px;

  &-img{
    height: 430px;
    img{
      width: auto;
      overflow: hidden;
      margin-top: -100px;
      height: 200%;
    }
    .carousel-caption{
      display: flex;
      align-items: flex-start;
      h3{
        font-size: 50px;
      }
    }
  }
}