.info-test{

  .course-name{

  }

  .root-folder{
    padding-left: 20px;
  }

  .child-folder{
    padding-left: 20px;
  }

  .folder-content{
    padding-left: 20px;
  }

  .test-folder{
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    align-items: center;
    border: 1px solid black;
  }

  .test-name{

  }

}