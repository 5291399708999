.change-password{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  align-items: flex-start;
  .item{
    width: 400px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    margin-bottom: 10px;
  }
}