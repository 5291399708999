.ant-card-actions{
  & li{
    margin: 0 !important;
  }
}
.card-title{
  font-size: 17px;
  display: block;
  text-align: justify;
  white-space: pre-wrap;
  font-weight: bold;
  margin: 0;
}