.test-container {
    padding: 50px 50px 0px 50px;
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 996px) {
        flex-wrap: wrap;
    }

    justify-content: space-between;

    .test-navigation {
        
        .test-menu {
            margin: 0px 50px 50px 0px;

            .test-item {
                padding: 10px;
                display: flex;
                flex-wrap: nowrap;
                min-width: min(80vw, 250px);
                justify-content: flex-start;
                cursor: pointer;
                border-radius: 10px;
                margin-bottom: 5px;

                i {
                    font-size: 25px;
                    width: 50px;
                }

                span {
                    font-size: 17px;
                    font-weight: bold;
                }

                transition: background-color 0.7s, color 0.5s;
                &:hover {
                    color: white;
                    background-color: $orange-color;
                }
            }

            .selected {
                color: white;
                background-color: $orange-color;
            }
        }
    }

    .ant-card-meta{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
    }

    .test-content {

        display: flex;
        flex-direction: column;
        width: 100%;

        .ant-card-body {
          min-height: 125px;
          padding: 0;
          position: relative;
        }
        .ant-card-meta-detail {
          overflow: hidden;
          max-height: 100px;
        }
        .ant-card-meta-title {
          white-space: pre-line;
          text-align: center;
        }
        .ant-card-bordered .ant-card-cover {
          height: 172px;
          overflow: hidden;
        }
        .ant-card-cover img {
          margin-top: -10%;
        }

        &-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 25px;
            padding: 10px;
            cursor: pointer;
            border-top: 4px solid $blue-color;
            border-radius: 10px;
  
            box-shadow: 0 10px 10px rgba(0,0,0, 0.2);
            transition: box-shadow 0.5s;
  
            &:hover{
              box-shadow: 0 10px 15px rgba(0,0,0, 0.4);
            }
  
            .content{
  
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
                  img{
                    overflow: hidden;
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    margin-right: 50px;
                  }
                  .text{
                    font-size: 17px;
                  }
              margin-right: 10px;
            }
  
            .button{
              height: 50px;
            }
        }
    }
}

.result-test-answer{
  display: flex;
  & span{
    width: 75%;
    margin-right: 20px;
  }
  & .ant-image{
    width: 200px !important;
    object-fit: cover;
  }
}

.btns-test{
  display: flex;
  justify-content: space-between;
  
  & :last-child{
    margin-right:0 !important;
  }
}

.btn-select-test{
  transition: .2s linear;
}

.btn-select-test:hover{
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); 
}

@media screen and (max-width: 1400px){

  .struct-course{
    width: 55%;
    font-size: 15px;
  }
  // .ant-menu{
  //   width: 100%;
  // }
  .h2-info-course{
    font-size: 25px !important; 
  }
  .list-header-tests{
    font-size: 23px !important;
    margin-bottom: 0 !important;
  }
  .h4-info-course{
    font-size: 15px !important;
  }
  .ant-list-header{
    padding: 0 !important;
  }
  .ant-list-item{
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1200px){

  .struct-course{
    width: 55% !important;
    font-size: 15px;
  }
  
  .h2-info-course{
    font-size: 20px !important; 
  }

  .list-header-tests{
    font-size: 18px !important;
    margin-bottom: 0 !important;
  }

  .h4-info-course{
    font-size: 15px !important;
  }
}

@media screen and (max-width: 800px){
  .struct-course{
    width: 100% !important;
    ul{
      margin-bottom: 15px;
    }
  }
  .menu-struct-test{
    width: 100% !important;
  }
  .struct-course-page{
    display: block !important;
  }
  
}
@media screen and (max-width: 450px){
  .header-test{
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 400px){
  footer{
    h2{
      font-size: 18px !important;
    } 
    h3{
      font-size: 14px !important;
    }
    .ant-descriptions-item-content{
      font-size: 12px !important;
    }
  }
  .header-test{
    font-size: 12px !important;
  }
  .img-question{
    width: 100px !important;
  }
  .test-data-view{
    flex-direction: column;
    .ant-image{
      display: block;
      width: 100% !important;
      .ant-image-img{
        width: 120px !important;
        object-fit: cover !important;
      }
    }
    
  }
  .text-question{
    font-size: 12px !important;
    width: 50%;
  }
  .answer-item{
    font-size: 10px;
  }
  .btns-test{
    button{

      font-size: 9px;
      text-align: justify;
      width: 100px !important;
    }
    .btn-close-test{
      margin: 0 0 !important;
    }
  }
  
}

@media screen and (max-width: 300px){
  .header-test{
    font-size: 11px !important;
  }
}