.text-link{
  span{
    align-self: center;
  }
  .ant-form-item-control-input-content{
    display: flex;
  }
}

.course-link-item{
    font-size: 12px;
    color : rgba(0, 0, 0, 0.85);
    justify-content: space-between;
}

.course-link-item:hover{
  color: darkblue;
}

.copy-link-img{
  cursor: pointer;
  margin: auto 0 auto auto
}

.action-with-course{
  display: flex;
  justify-content: space-between;
  .delete-all-course{
    align-self: center;
    span{
      margin-right: 10px;
    }
  }
}

.second-file-info{
  display: flex;
  margin-bottom: 10px;
  p{
    margin: 0 10px 0 0;
    align-self: center;
  }
  input{
    margin: 0 20px 0 0;
  }
}

.add-name-for-file{
  display: flex;
  margin-bottom: 20px;
  p{
    margin: 0 10px 0 0;
    align-self: center;
  }
  
}

.add-file{
  margin: 0;
}

@media screen and (max-width: 1399px){
  .coursesList{
    font-size: 12px !important;
    span{
      font-size: 10px !important;
    }
    p{
      font-size: 11px !important;
    }
    a{
      font-size: 11px;
    }
  }
  .btn-edit-course{
    font-size: 12px !important;
  }
  .usersList{
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1200px){
  .btn-edit-course{
    font-size: 10px !important;
  }
  .coursesList{
    font-size: 10px !important;
    span{
      font-size: 9px !important;
    }
    p{
      font-size: 9px !important;
    }
    a{
      font-size: 9px !important;
    }
  }
  .usersList{
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1000px){
  .delete-all-course{
    margin-left: 17px;
  }

  .btn-edit-course-box{
    display: block !important;
  }
  
  .coursesList{
    display: grid;
    div{
      border: none !important;
      padding-top: 10px !important;
      font-size: 12px !important;
      p{
        font-size: 12px !important;
      }
      span{
        font-size: 12px !important;
      }
    }
  }
  
  .title-with-delete-icon{
    flex-direction: column;
  }
  
  .usersList{
    padding-top: 10px;
    display: grid;
    text-align: center !important;
    .ant-col-12{
      max-width: 100%;
    }
    div{
      border: none !important;
      padding-top: 3px !important;
      font-size: 12px !important;
      p{
        font-size: 12px !important;
      }
      span{
        font-size: 12px !important;
      }
    }
  }
  
  .courses-info-title{
    display: none;
  }

  .ant-col-12{
    min-width: 100%;
    p{
      max-width: 100%;
      word-wrap: break-word !important;
    }
  }

  .ant-col-6{
    min-width: 100%;
    p{
      max-width: 100%;
      word-wrap: break-word !important;
    }
  }

  .ant-col-3{
    min-width: 100%;
    p{
      max-width: 100%;
      word-wrap: break-word !important;
    }
  }
}

@media screen and (max-width: 510px){
  .coursesList{
    .ant-col-12{
      display: flex;
      flex-direction: column;
    }
  }
}