.ant-pagination {
}

.ant-pagination-item {
    font-size: 20px;
    border-radius: 25%;
    border-width: 2px;
    border-color: transparent;
}

.ant-pagination-item-active {
    border-color: #6496FA;
}

.ant-pagination-prev .ant-pagination-item-link, 
.ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    border: none;
}
