.not-found{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  justify-content: center;

  .title{
    font-size: 150px;
    color: $orange-color;
    font-weight:700;
  }

  .text-level-1{
      font-size: 50px;
      color: $blue-color;
  }
  .text-level-2{
    font-size: 17px;
  }

}