
.pto-paddings {
  padding: 20px 20px 0px 50px;
}
.menu-paddings {
  padding: 15px 20px 0px 0px;
}

@media (max-width: 400px) {
  .pto-paddings {
    padding: 20px 0px 0px calc(20px + 30 * (100vw - 320px) / 80);
    overflow: hidden;
  }
  .menu-paddings {
    padding: 15px 0px 0px calc(0px + 20 * (100vw - 320px) / 80);
  }
}

.menu-outlined{
  svg{
    width: 20px;
    height: 20px;
  }
  
}

.back-top{
  right: 50px !important;
}

.accountant-btn{
  span{
    align-self: flex-end;
  }
}

.links{

  &-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
  }

  .logo{
    color: $blue-color;
    font-size: 25px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .auth{
    display: flex;
    flex-direction: row;
  }

  position: relative;

  
  .user_drop_list{
      position: relative;
      right: 10%;
      margin-top: 15px;
      background: #ffffff;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      overflow: hidden;
      z-index: 1000;
      padding: 10px 0;
      width: 200px;

    display: flex;
    flex-direction: column;

    .user_name{
      background-color: #00796B;
      i{
        margin-left: 10px;
      }

      &:hover{
        cursor: pointer;
      }
    }

    .drop{
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      a{
        font-size: 17px ;
        color: black;
        text-align: center;
        
        padding: 10px 10px;
        transition: background-color 1s;

        &:hover{
          background-color: $orange-color;
          opacity: .8;
        }
      }

      .button{
        width: auto;
        margin: 10px   5px 0 5px;
      }
    }
  }

  .main_nav_bar{
    background-color: $blue-color;
    &-container
    {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 70px;

      .nav-item{
        width: 100%;
        height: 100%;
        display: flex;
        transition: background-color 0.5s;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: white;

        &:hover{
          background-color: $orange-color;
        }
      }

      .selected{

        background-color: $orange-color;
      }
    }
  }
}

.ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-title-content{
  a{
    font-weight: bold !important;
  }
}
.ant-typography{
  a{
    font-size: 20px;
  }
}

@media (max-width: 450px) {
  .ant-menu-submenu{
    position: relative;
    z-index: 2;
  }
  .awssld__bullets{
    button{
      width: 8px;
      height: 8px;
    }
  }
}