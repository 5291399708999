.pdf-helper{
  text-align: -webkit-center;
  span{
    display: none !important;
  }
}

.react-pdf__Page__annotations{
  section{
    display: none;
  }
}

@media screen and (max-width: 800px){
  canvas{
    width: 100% !important;
  }

  .react-pdf__Page__textContent{
    width: 100% !important;
  }
}