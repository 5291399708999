.edit-profile{
    display:flex;
    flex-direction: column;
    &:first-child{
        margin-top: 10px;
    }
    &div{
        margin-bottom: 10px;
        &span{
            display: inline-block;
            width: 200px;
        }
    }
}
.edit-avatar{
    margin-left: 11%;
}