.struct-course-page{
  display: flex;
  margin-top: 20px;
  h2{
    margin-top: 0 !important;
  }
}

.info-course{
  margin-left: 20px;
  hr{
    color: #1890ff;
  }
}

.ant-collapse-header{
  font-weight: bold;
}
.ant-collapse-content-box{
  .right-answer-span{
    margin-top: 20px !important;
    display: block;
  }
}
@media(max-width: 450px){
  .test-data-view{
    display: block;
  }
  .btns-test{
    display: block;
    button{
      display: block;
      width: 100% !important;
    }
    :last-child{
      margin-left: 0 !important;
    }
  }
  .result-test-answer{
    display: flex;
    flex-direction: column;
  }
  
}
@media(max-width: 400px){
  .test-data-view{
    display: block;
  }
  .btns-test{
    display: block;
    button{
      display: block;
      
      width: 100% !important;
    }
    :last-child{
      margin-left: 0 !important;
    }
  }
  .btns-view-statistic{
    button{
      width: 100%;
    }
    :last-child{
      margin-left: 0 !important;
    }
  }
}
.cards-test{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  .ant-card-cover{
    height: 185px !important;
      img{
        margin-top: 0px !important;
      }
  }
}

@media(max-width: 1540px){
  .cards-test{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    
  }
}
@media(max-width: 1240px){
  .cards-test{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
  }
}
@media(max-width: 700px){
  .cards-test{
    display: grid;
    grid-template-columns: 1fr;
    
  }
}