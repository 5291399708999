@import "variables";
@import "components/all";
@import "libs/normalize";


#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.button{
  width:120px;
  text-align: center;
  border-radius: 8px;
  font-weight: 600;
  color: white;
  margin: 10px;
  padding: 12px 18px;
  transition: box-shadow 0.5s;

  &:hover{
    cursor: pointer;
    box-shadow: 0 0 10px $orange-color;
  }
}

.btn-orange{
  background-color: $orange-color;
  border: 1px solid $orange-color;
}

.btn-outline{
  background-color: transparent;
  color: $orange-color;
}

*{
  font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 15px;
}
/*
body{
  background-color: gray;
}
.container{
  background-color: $gray-color;
}*/
